/* ExperienciasComponent.css */
.experiencias-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Cambio de 'height' a 'min-height' */
  padding: 20px;
  padding-top: 50px; /* Añade espacio en la parte superior */
}

.experiencia {
  background-color: #f4f4f4;
  margin: 20px 0; /* Ajuste para margen superior e inferior */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.experiencia h3 {
  color: #333;
}

.experiencia p {
  color: #666;
  margin-bottom: 1rem; /* Agrega margen entre párrafos */
}

@media (max-width: 768px) {
  .experiencia {
    width: 85%; /* Amplía el ancho de las tarjetas para pantallas más pequeñas */
    margin: 10px 0; /* Menor margen en pantallas pequeñas */
    padding: 15px; /* Menos padding dentro de las tarjetas */
    margin-top: 110px;
  }

  .experiencias-container {
    justify-content: flex-start; /* Alinea las tarjetas al principio del contenedor */
  }
}
