/* src/pages/Inicio.css */

.layout-container {
    position: relative;
    min-height: 100vh; /* Cambiado de height a min-height */
    background-image: url('../../img/fondo3.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: scroll; /* Asegura que el fondo se extienda con el scroll */
}


.dark-mode {
    background-color: rgba(0, 0, 0, 0.5);
}

.mode-toggle {
    position: fixed; /* Cambio de 'absolute' a 'fixed' para asegurar que los íconos se mantengan en la esquina a través de todo el sitio */
    bottom: 10px; /* Cambio de 'top' a 'bottom' */
    right: 10px;
    width: 40px; /* Tamaño del ícono */
    z-index: 1000; /* Asegura que los íconos permanezcan por encima de otros elementos */
}

.mode-toggle img {
    cursor: pointer;
    width: 100%; 
}
.layout-container.dark-mode::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: wheat;
}



.top-menu {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
}

.top-menu a {
    color: black;
    text-decoration: none;
    margin: 0 10px;
    font-size: 16px;
    /* Añade más estilos según tu diseño */
}

.social-links {
    position: absolute;
    bottom: 10px;
    left: 20px;
    display: flex;
    flex-direction: column;
}

.social-links a {
    color: white;
    text-decoration: none;
    margin: 10px 0;
    /* Añade más estilos según tu diseño */
}

.brand-logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}


/* *****************nuevo******************* */

.top-menu {
    position: fixed;
    top: 33px;
    right: 0;
    margin: 20px;
    display: flex;
    flex-direction: row; /* Cambia a 'column' si prefieres que el menú se despliegue verticalmente */
    transition: transform 0.3s ease; /* Para animación */
    transform: translateY(-100%); /* Esconde el menú */
    z-index: 1000;
}

.top-menu.open {
    transform: translateY(0); /* Muestra el menú */
}

.menu-icon {
    display: none; /* Oculta el icono del menú por defecto */
}

@media (max-width: 768px) { /* Ajusta este valor según tus necesidades */
    .top-menu {
        position: fixed;
        top: 33px; /* Ajusta esto según el tamaño de tu logo o barra de navegación */
        right: 9px;        
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.8); /* O el color que prefieras */
        border-radius: 5px; /* Estilo opcional */
        transform: translateY(-1100%); /* Se mantiene escondido */
    }
    .menu-icon {
        display: block; /* Muestra el icono del menú en pantallas pequeñas */
        position: fixed;
        top: 35px;
        right: 30px;
        z-index: 10000; /* Asegúrate de que el ícono esté por encima de otros elementos */
    }

    .brand-logo, .social-links {
        position: fixed;
    }
}
