/* ProyectosComponent.css o el archivo CSS correspondiente */

.proyectos-container {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 100vh; /* Hace que el contenedor tenga la altura de la ventana del navegador */
}

.proyectos-container .row {
  width: 80%; /* Opcional, dependiendo de cómo desees que se muestren tus tarjetas */
}

/* ******************************************* */
.proyectos-container .cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.proyectos-container .transparent{
    background-color: rgba(0, 0, 0, 0.2)!important;
}

.proyectos-container .cards .red {
  background-color: #f43f5e;
}

.proyectos-container .cards .blue {
  background-color: #3b82f6;
}

.proyectos-container .cards .green {
  background-color: #22c55e;
}

.proyectos-container .cards .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100px;
  width: 400px;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: 400ms;
}

.proyectos-container .cards .card p.tip {
  font-size: 1em;
  font-weight: 700;
}

.proyectos-container .cards .card p.second-text {
  font-size: 0.7em;
}

.proyectos-container .cards .card:hover {
  transform: scale(1.1, 1.1) ;
}

.proyectos-container .cards:hover > .card:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}


/* Adaptaciones para dispositivos más grandes, si es necesario */
.proyectos-container .cards .card {
  max-height: 200px; /* Establece un máximo para la altura */
  max-width: 90%; /* Permite que las tarjetas ocupen hasta un 90% del ancho del contenedor */
  margin: auto; /* Centra las tarjetas si son más pequeñas que el máximo establecido */
  /* Mantén los demás estilos */
}

/* Adaptaciones para dispositivos pequeños */
@media (max-width: 768px) {
  .proyectos-container .cards {
    flex-direction: column; /* Hace que las tarjetas se apilen verticalmente */
    margin-top: 110px;
  }

  .proyectos-container .cards .card {
    width: 90%; /* Hace que las tarjetas sean más anchas en pantallas pequeñas */
    height: auto; /* Permite que la altura se ajuste al contenido */
    margin-bottom: 20px; /* Añade espacio entre las tarjetas */
  }

  .proyectos-container .cards .card p.tip,
  .proyectos-container .cards .card p.second-text {
    font-size: 0.9em; /* Ajusta el tamaño de la tipografía para que sea legible en pantallas pequeñas */
  }
}


/* Logos Tecnologías */
ul {
  list-style: none;
}

.example-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
}
.example-2 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}
.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}
.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}
.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}
.example-2 .icon-content a:hover {
  color: white;
}
.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="linkedin"] .filled,
.example-2 .icon-content a[data-social="linkedin"] ~ .tooltip {
  background-color: #0274b3;
}

.example-2 .icon-content a[data-social="github"] .filled,
.example-2 .icon-content a[data-social="github"] ~ .tooltip {
  background-color: #24262a;
}
.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"] ~ .tooltip {
  background: linear-gradient(
    45deg,
    #405de6,
    #5b51db,
    #b33ab4,
    #c135b4,
    #e1306c,
    #fd1f1f
  );
}
.example-2 .icon-content a[data-social="youtube"] .filled,
.example-2 .icon-content a[data-social="youtube"] ~ .tooltip {
  background-color: #ff0000;
}
