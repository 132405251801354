/* ContactForm.css */
.contact-form {
    width: 100%;
    max-width: 500px; /* O el ancho que prefieras */
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
}

.success-message {
    animation: moveInLeft 1s ease-out;
    width: 100%;
    max-width: 500px; /* O el ancho que prefieras */
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.5); /* Fondo transparente */
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
}

/* Animación de "deslizamiento desde la izquierda" */
@keyframes slideIn {
    0% {
        transform: translateX(100%); /* Comienza fuera de la pantalla a la derecha */
    }
    100% {
        transform: translateX(-100%); /* Termina fuera de la pantalla a la izquierda */
    }
}
.success-message-text {
    display: inline-block; /* Mantiene el elemento en línea, pero permite transformaciones */
    white-space: nowrap; /* Evita que el texto se envuelva en nuevas líneas */
    padding-left: 50%; /* Empieza fuera del contenedor */
    animation: slideIn 10s linear infinite; /* Ajusta la duración según tus necesidades */
}


.error-message {
    animation: moveInLeft 1s ease-out;
    width: 100%;
    max-width: 500px; /* O el ancho que prefieras */
    margin: auto;
    position: absolute;
    top: 23%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.5); /* Fondo transparente */
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    color: red; /* Personaliza esto como quieras */
}




/* Estilos que se aplicarán solo en pantallas menores a 768px de ancho */
@media (max-width: 768px) {
    .contact-form, .success-message, .error-message {
        width: 78%; /* Hace el formulario más ancho para llenar más espacio */
        max-width: none; /* Remueve el ancho máximo para permitir que el formulario se expanda */
    }

    .contact-form, .success-message, .error-message {
        top: 50%; /* Mantiene centrado verticalmente */
        left: 50%; /* Mantiene centrado horizontalmente */
        transform: translate(-50%, -50%); /* Ajusta la posición para mantenerlo centrado */
        padding: 15px; /* Reduce el padding para que ocupe menos espacio */
    }

    /* .contact-form{
        margin-top: 110px;
    } */
    .success-message-text, .error-message {
        font-size: 14px; /* Reduce el tamaño de la fuente para ajustarse a pantallas más pequeñas */
    }
}
